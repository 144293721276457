import React, { useState } from "react"

import { Link } from "gatsby"
import { withStyles } from "@material-ui/core/styles"
import {
  SwipeableDrawer,
  Grid,
  Typography,
  Toolbar,
  AppBar,
  Tabs,
  Tab,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"

import { Menu, Styles } from "./HeaderUtils"
import ContentDiv from "../ContentDiv"
import logo from "../../images/astelab_banner.jpg"

const Header = React.memo(props => {
  const { classes, page } = props
  const [menuDrawer, setMenuDrawer] = useState(false)

  return (
    <AppBar position="absolute" color="default" className={classes.appBar}>
      <ContentDiv>
        <Toolbar>
          <Grid container spacing={4} alignItems="baseline">
            <Grid item xs={12} className={classes.flex}>
              <div className={classes.inline}>
                <Typography variant="h6" color="inherit" noWrap>
                  <Link to="/" className={classes.link}>
                    <img
                      className={classes.img}
                      src={logo}
                      alt="Logo da empresa"
                    />
                  </Link>
                </Typography>
              </div>

              <div className={classes.productLogo}>
                <Typography>Equipamentos para laboratórios</Typography>
              </div>

              <div className={classes.iconContainer}>
                <IconButton
                  onClick={() => setMenuDrawer(true)}
                  className={classes.iconButton}
                  color="inherit"
                  aria-label="Menu"
                >
                  <MenuIcon />
                </IconButton>
              </div>

              <div className={classes.tabContainer}>
                <SwipeableDrawer
                  anchor="right"
                  open={menuDrawer}
                  onClose={() => setMenuDrawer(false)}
                  onOpen={() => setMenuDrawer(true)}
                >
                  <AppBar title="Menu" />
                  <List>
                    {Menu.map((item, index) => (
                      <ListItem
                        key={index}
                        component={Link}
                        href={item.external ? item.pathname : null}
                        to={item.pathname}
                        button
                      >
                        <ListItemText primary={item.label} />
                      </ListItem>
                    ))}
                  </List>
                </SwipeableDrawer>
                <Tabs
                  value={page}
                  classes={{
                    indicator: classes.indicator,
                  }}
                >
                  {Menu.map((item, index) => (
                    <Tab
                      key={index}
                      component={Link}
                      href={item.external ? item.pathname : null}
                      to={item.pathname}
                      classes={{
                        root: classes.tabItem,
                      }}
                      label={item.label}
                    />
                  ))}
                </Tabs>
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </ContentDiv>
    </AppBar>
  )
})

export default withStyles(Styles)(Header)
