export const Menu = [
  {
    label: "Início",
    pathname: "/",
  },
  {
    label: "Serviços",
    pathname: "/servicos",
  },
  {
    label: "Produtos",
    pathname: "/produtos",
  },
  {
    label: "Contato",
    pathname: "/contato",
  },
]

export const Styles = theme => ({
  appBar: {
    position: "relative",
    boxShadow: "none",
    borderBottom: `1px solid ${theme.palette.grey["100"]}`,
    backgroundColor: "#FAFAFA",
  },
  img: {
    width: "100%",
    height: 75,
    maxWidth: 184,
  },
  inline: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  link: {
    textDecoration: "none",
    color: "inherit",
    display: "flex",
    alignItems: "center",
  },
  productLogo: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    borderLeft: `1px solid ${theme.palette.grey["A100"]}`,
    marginLeft: 32,
    paddingLeft: 24,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  tagline: {
    display: "inline-block",
    marginLeft: 10,
  },
  iconContainer: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  iconButton: {
    float: "right",
  },

  tabContainer: {
    marginLeft: 32,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    "& > div:first-of-type": {
      pointerEvents: "none",
    },
  },
  tabItem: {
    paddingTop: 40,
    paddingBottom: 20,
    minWidth: "auto",
  },
  indicator: {
    backgroundColor: "#005251 !important",
  },
})
