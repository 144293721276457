import React from "react"

import { withStyles } from "@material-ui/core"

const ContentDiv = ({ classes, children }) => (
  <div className={classes.content}>{children}</div>
)

const styles = {
  content: {
    width: "100%",
    marginRight: "auto",
    marginLeft: "auto",
  },
  "@media (min-width: 1500px)": {
    content: {
      maxWidth: 1470,
    },
  },
  "@media (min-width: 1360px)": {
    content: {
      maxWidth: 1470,
    },
  },
  "@media (max-width: 1360px)": {
    content: {
      maxWidth: 1040,
    },
  },
  "@media (max-width: 992px)": {
    content: {
      maxWidth: 960,
      padding: 0,
    },
  },
  "@media (max-width: 768px)": {
    content: {
      maxWidth: 720,
    },
  },
  "@media (max-width: 576px)": {
    content: {
      maxWidth: 540,
    },
    sectionTitle: {
      margin: 0,
    },
  },
}

export default withStyles(styles)(ContentDiv)
