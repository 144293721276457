import React from "react"

import { OutboundLink } from "gatsby-plugin-google-gtag"
import { withStyles } from "@material-ui/core/styles"
import { Phone, Mail, LocationOn, LinkedIn, YouTube } from "@material-ui/icons"
import ContentDiv from "../ContentDiv"

const Footer = React.memo(({ classes }) => (
  <footer className={classes.footerDistributed}>
    <ContentDiv>
      <div className={classes.content}>
        <div className={classes.detail}>
          <div>
            <i>
              <LocationOn />
            </i>
            <p>
              <span>Rua do Triunfo, 489,</span> {" Arruda - Recife - PE"}
            </p>
          </div>

          <div>
            <i>
              <Phone />
            </i>
            <p>+55 (81) 3241-8700</p>
          </div>

          <div>
            <i>
              <Mail />
            </i>
            <p>
              <OutboundLink
                href="mailto:astelab@astelab.com.br"
                className={classes.anchor}
              >
                astelab@astelab.com.br
              </OutboundLink>
            </p>
          </div>
          <div>
            <OutboundLink
              href="https://www.linkedin.com/company/astelab-nordeste/"
              target="_blank"
            >
              <i>
                <LinkedIn />
              </i>
            </OutboundLink>
          </div>
          <div>
            <OutboundLink
              href="https://www.youtube.com/channel/UCwl9Ne18q4qxgSISY4z7K8Q"
              target="_blank"
            >
              <i>
                <YouTube />
              </i>
            </OutboundLink>
          </div>
        </div>
      </div>
    </ContentDiv>
  </footer>
))

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 1800,
  },
  footerDistributed: {
    backgroundColor: "#005251",
    boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.12)",
    boxSizing: "border-box",
    width: "100%",
    textAlign: "left",
    color: "#FFFFFF",
    marginTop: 80,
    paddingTop: 10,
    overflow: "hidden",
  },
  content: {
    display: "flex",
    flex: 1,
  },
  detail: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    fontSize: "0.8em",
    "& > div": {
      display: "flex",
      padding: "0px 20px",
      alignItems: "center",
      "& > a": {
        color: "#FFFFFF",
      },
      "& > p": {
        paddingLeft: 10,
      },
    },
  },

  anchor: {
    color: "#FFFFFF",
    textDecoration: "none",
  },
  "@media (max-width: 1200px)": {
    content: {
      flexDirection: "column",
    },
    img: {
      "& > img": {
        width: 200,
      },
    },
    name: {
      justifyContent: "center",
    },
    detail: {
      "& > div": {
        flexGrow: 1,
      },
    },
  },
  "@media (max-width: 900px)": {
    footerDistributed: {
      padding: "20px 20px 10px",
    },
  },
}

export default withStyles(styles)(Footer)
