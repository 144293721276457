import React from "react"
import PropTypes from "prop-types"

import Header from "../Header"
import Footer from "../Footer"
import SEO from "../SEO"
import ContentDiv from "../ContentDiv"
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles"
import { CssBaseline } from "@material-ui/core"
import "./Container.css"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#005251",
    },
    secondary: {
      main: "#fff",
    },
  },
})

const Container = React.memo(({ children, noFooter, page, title }) => (
  <ThemeProvider theme={theme}>
    <SEO title={title} />
    <CssBaseline />
    <Header page={page} />
    <main>
      <ContentDiv>{children}</ContentDiv>
    </main>
    {!noFooter && <Footer />}
  </ThemeProvider>
))

Container.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Container
